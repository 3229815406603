.editor-wrapper {
    border: 1px solid #f1f1f1;
    padding: 5px;
    border-radius: 2px;
}

.toolbar-class {
    border: 1px solid #f1f1f1 !important;
    margin-bottom: 0px !important;
}

.toolbar-button {
    padding: 5px !important;
    margin: 2px !important;
    border-radius: 0px !important;
    cursor: pointer !important;
}

.toolbar-button:hover {
    background: #005f7311 !important;
}

.rdw-option-wrapper {
    width: 30px !important;
    height: 30px !important;
    background: #ffffff !important;
    border: 1px solid #005f7380 !important;
    padding: 5px !important;
    border-radius: 0px !important;
    margin: 0 4px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer !important;
    text-transform: capitalize !important;
}

.rdw-option-active {
    background: #f1f1f1 !important;
}

.rdw-dropdown-wrapper {
    height: 30px !important;
    background: #ffffff !important;
    cursor: pointer !important;
    border: 1px solid #005f7380 !important;
    border-radius: 3px !important;
    margin: 0 3px !important;
    text-transform: capitalize !important;
    background: white !important;
}

.rdw-dropdown-carettoopen {
    height: 0 !important;
    width: 0 !important;
    position: absolute !important;
    top: 35% !important;
    right: 10% !important;
    border-top: 6px solid black !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
}

.editor-class {
    border: 1px solid #f1f1f1 !important;
    border-radius: 2px !important;
}

.rdw-colorpicker-modal {
    padding: 10px !important;
    width: 200px !important;
    height: 44px !important;
}

.rdw-colorpicker-modal-header {
    display: none !important;
}

.rdw-colorpicker-modal-options {
    margin: 0 !important;
}

.rdw-inline-wrapper,
.rdw-colorpicker-wrapper,
.rdw-fontsize-wrapper {
    margin-bottom: 0 !important;
}

.rdw-option-active {
    background: #005f7333 !important;
}

.rdw-fontsize-option {
    padding: 3px 5px !important;
}
